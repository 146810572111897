<template>
  <b-container id="onboarding">
    <!-- modal loading verification -->
    <b-modal
      id="modal-verifying"
      centered
      title="Verifying..."
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-card-text>
        <div class="text-center p-4">
          <h1>Your Google Account is being verified. Please wait a moment.</h1>
        </div>
      </b-card-text>
    </b-modal>

    <b-row>
      <b-col>
        <h2>Get Started with Converifai</h2>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col class="mt-2">
        <form-wizard
          ref="onboardingWizard"
          color="#9933cc"
          :title="null"
          :subtitle="null"
          layout="vertical"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="wizard-vertical mb-3"
          @on-complete="finalizeWizard"
        >

          <!-- link account tab -->
          <tab-content
            title="Link Account"
            :before-change="checkStepLinkAccount"
          >
            <b-overlay :show="showLinkOverlay">
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Sign in with Google Ads Account
                  </h5>
                  <small class="text-muted">
                    You will be asked to sign into you Google Account. Please select the Google Account associated
                    associated Google Ads to maximize the benefit of Converifai. No changes will be made to your
                    Google Ads campaigns without your express permission, however we will create new conversion
                    actions (conversion types) in accordance with best practice.
                  </small>
                </b-col>
              </b-row>

              <!-- set google customer -->
              <link-google-account :key="googleComponentKey" />

              <b-row v-if="googleAccess">
                <b-col md="12">
                  <b-row>
                    <b-col
                      cols="12"
                      class="mt-2 mb-2"
                    >
                      <h5 class="mb-0">
                        Please select from the dropdown fields below:
                      </h5>
                    </b-col>
                  </b-row>

                  <!-- set google customer -->
                  <select-google-customer
                    :model-value="currentCustomer"
                    @setCurrentAgency="newValue => currentAgency = newValue"
                    @setCurrentCustomer="newValue => currentCustomer = newValue"
                  />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="12">
                  <p
                    v-if="wizardErrorStepLinkAccount"
                    class="text-danger"
                  >
                    {{ wizardErrorStepLinkAccount }}
                  </p>
                </b-col>
              </b-row>
            </b-overlay>
          </tab-content>

          <!-- agency clients tab -->
          <!-- <tab-content
            title="Agency Client"
            :before-change="checkStepAgencyClients"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Add Agency Client
                </h5>
                <small class="text-muted">Add additional agency clients if necessary.</small>
              </b-col>
              <b-col
                md="12"
                class="mb-2"
              >
                <b-form-group
                  label="Agency Clients"
                  label-for="agency-list"
                >
                  <b-list-group id="agency-list">
                    <b-list-group-item
                      v-for="agencyClient in agencies"
                      :key="`agency_${agencyClient.id}`"
                    >
                      {{ agencyClient.name }}
                      <span v-if="agencyClient.owner">
                        <em>(owner)</em>
                      </span>
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="agencies.length < 1"
                    >
                      <em>Please add an agency client below.</em>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h5>Add New Agency Client</h5>
                <b-form-group
                  label="New Agency Name"
                  label-for="agency-name"
                >
                  <b-form-input
                    id="agency-name"
                    v-model="newAgencyForm.name"
                    placeholder="Agency Name"
                  />
                </b-form-group>
                <b-form-group
                  label="Google Customer ID (Optional)"
                  label-for="google-customer-id"
                >
                  <b-form-input
                    id="google-customer-id"
                    v-model="newAgencyForm.google_customer_id"
                    placeholder="Google Customer ID"
                  />
                </b-form-group>
                <b-button
                  variant="outline-primary"
                  class="mb-2"
                  @click="submitAgencyClient"
                >
                  Submit New Agency
                </b-button>
              </b-col>
              <b-col md="12">
                <p
                  v-if="wizardErrorStepAgencyClients"
                  class="text-danger"
                >
                  {{ wizardErrorStepAgencyClients }}
                </p>
              </b-col>
            </b-row>
          </tab-content> -->

          <!-- domains tab -->
          <tab-content
            title="Add Website"
            :before-change="checkStepDomains"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Enter Website Address or URL
                </h5>
                <small class="text-muted">Please enter the website that you wish to measure with Converifai, the same website the previously selected Google Ads Account is sending traffic towards.</small>
              </b-col>
            </b-row>

            <!-- set site urls -->
            <manage-site-domains
              v-if="currentAgency"
              :current-agency="currentAgency"
              @updateDomains="updateDomains"
            />

            <b-row>
              <b-col md="12">
                <p
                  v-if="wizardErrorStepDomains"
                  class="text-danger"
                >
                  {{ wizardErrorStepDomains }}
                </p>
              </b-col>
            </b-row>
          </tab-content>

          <!-- calculate conversion value tab -->
          <tab-content
            title="Calculate Default"
            :before-change="checkStepConversion"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-1">
                  Calculate Your Default Conversion Value
                </h5>
                <small class="text-muted">
                  Some actions on your website are more helpful than others. With Converifai, we will help you figure out how much each action is worth. This helps make better choices about where
                  to spend money in your advertising and you will unlock value-based-bidding smart-models, even for lead-generation campaigns!<br><br>
                  By calculating a default value on this page you get this same benefit even if you don't have all the information right away.
                </small>
              </b-col>
              <b-col md="12">
                <!-- set conversion value -->
                <set-conversion-value
                  v-if="userData"
                  :current-user="userData"
                  :current-domains="userData.domains"
                  @updateConversionValue="updateConversionValue"
                />
              </b-col>
              <b-col md="12">
                <p
                  v-if="wizardErrorStepConversion"
                  class="text-danger"
                >
                  {{ wizardErrorStepConversion }}
                </p>
              </b-col>
            </b-row>
          </tab-content>

          <!-- paste script tab -->
          <tab-content title="Add Website Tag">
            <b-row>
              <b-col
                cols="12"
                class="mb-2 text-center"
              >
                <h3 class="mb-1">
                  Add Converifai Lead-Capture Tag to Your Website
                </h3>
                <small class="text-muted"><!--Select how you want to install your tag--></small>
              </b-col>
              <b-col
                md="6"
                class="my-1"
              >
                <b-card
                  class="border cursor-pointer"
                  @click="openModal('modal-script')"
                >
                  <b-card-body class="text-center">
                    <h1>
                      <span class="d-inline-block p-2 rounded bg-dark-transparency"><i
                        class="fa-solid fa-code"
                      /></span>
                    </h1>
                    <h2 class="my-1">
                      Copy Installation Tag
                    </h2>
                    <p>
                      <small>Place tag into your website theme yourself</small>
                    </p>
                  </b-card-body>
                </b-card>
              </b-col>
              <!-- <b-col
                md="4"
                class="my-1"
              >
                <b-card
                  class="border cursor-pointer"
                  @click="openModal('modal-contact')"
                >
                  <b-card-body class="text-center">
                    <h1>
                      <span class="d-inline-block p-2 rounded bg-dark-transparency"><i
                        class="fa-regular fa-envelope"
                      /></span>
                    </h1>
                    <h2 class="my-1">
                      Contact
                    </h2>
                    <p>
                      <small>Email tag to your marketer or webmaster</small>
                    </p>
                  </b-card-body>
                </b-card>
              </b-col> -->
              <b-col
                md="6"
                class="my-1"
              >
                <b-card class="border cursor-pointer">
                  <b-card-body
                    class="text-center"
                    @click="openSupportDesk"
                  >
                    <!-- openModal('modal-ticket') -->
                    <h1>
                      <span class="d-inline-block p-2 rounded bg-dark-transparency"><i
                        class="fa-solid fa-ticket"
                      /></span>
                    </h1>
                    <h2 class="my-1">
                      Ticket
                    </h2>
                    <p>
                      <small>I need help, open a ticket with Converifai</small>
                    </p>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </tab-content>

          <!-- submit form -->
          <tab-content title="Submit Form">
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h3 class="mb-1 text-center">
                  Submit Form
                </h3>
                <small class="d-block text-muted text-center">Please Submit a Contact Form on Your Website and Confirm
                  the Details Below</small>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h4 class="text-center">
                  Almost done! 🚀
                </h4>
                <p class="text-center">
                  Confirm your account details and submit a form to ensure installation has been successful
                </p>
              </b-col>
            </b-row>
            <b-row
              v-for="item in submitFormItems"
              :key="`submit_${item.name}`"
              class="mb-1"
            >
              <b-col
                xs="6"
                class="text-right"
              >
                <strong>{{ item.name }}</strong>
              </b-col>
              <b-col
                xs="6"
                class="text-left"
              >
                {{ item.value }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <p>
                  <b-form-checkbox
                    checked="false"
                    name="check-button"
                    switch
                    inline
                  >
                    I have installed the Converifai installation script.
                  </b-form-checkbox>
                </p>
              </b-col>
              <b-col cols="12">
                <p>
                  <b-form-checkbox
                    checked="false"
                    name="check-button"
                    switch
                    inline
                  >
                    I have submitted a form to ensure there is a captured form submission on the next page.
                  </b-form-checkbox>
                </p>
              </b-col>
            </b-row>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>

    <!-- modal add script -->
    <b-modal
      id="modal-script"
      centered
      title="Add Lead-Capture Tag"
      size="lg"
      ok-title="Close"
      @ok="$bvModal.hide('modal-script')"
    >
      <p>
        Copy this tag to the footer of your website, above the closing &lt;/body&gt; tag, so it appears on every page.
      </p>
      <pre>
        <code>
          {{ parseHtml(scriptEmbed) }}
        </code>
      </pre>
      <b-button
        class="mb-2"
        :class="{'btn-success': copyButtonClicked}"
        variant="primary"
        @click="copyText(parseHtml(scriptEmbed))"
      >
        {{ copyButtonClicked ? 'Copied!' : 'Click to Copy' }}
      </b-button>
    </b-modal>

    <!-- modal contact support -->
    <b-modal
      id="modal-contact"
      centered
      title="Contact"
      size="lg"
      ok-title="Close"
      @ok="showEmailTemplate = !showEmailTemplate && $bvModal.hide('modal-contact')"
    >
      <div v-if="!showEmailTemplate">
        <p>
          Enter your support person's name, phone number, and email address, and we'll alert them of the need to help
          you install Converifai on your website.
        </p>
        <b-form>
          <b-form-group
            label="Name"
            label-for="contact-name"
            class="mb-2"
          >
            <b-form-input
              id="contact-name"
              placeholder="John Doe"
            />
          </b-form-group>
          <b-form-group
            label="Phone Number"
            label-for="contact-phone"
            class="mb-2"
          >
            <b-form-input
              id="contact-phone"
              placeholder="555-555-5555"
            />
          </b-form-group>
          <b-form-group
            label="Email Address"
            label-for="contact-email"
            class="mb-2"
          >
            <b-form-input
              id="contact-email"
              placeholder="support@example.com"
            />
          </b-form-group>
          <b-button
            class="mb-2"
            variant="primary"
          >Send
          </b-button>
        </b-form>
        <a
          href="#"
          class="mb-2 text-secondary"
          @click.prevent="showEmailTemplate = !showEmailTemplate"
        >
          I'll contact them manually
        </a>
      </div>

      <div v-if="showEmailTemplate">
        <p>Here is a message you may consider sending to your support person:</p>
        <p
          id="emailTemplate"
          class="text-white"
        >
          Hello,<br>
          <br>
          I am writing today as our organization is integrating a Conversion Management Platform, Converifai
          (converifai.com), to help us better understand our marketing and sales performance.<br>
          I hope to understand how your agency operates when such a system is used by your clients and how we can best
          work together after the integration is complete.<br>
          <br>
          We're using Converifai to figure out what's making us money so we can do more of that. This is not intended
          to disrupt any existing services.<br>
          Converifai can help us both see important information.<br>
          <br>
          Our Converifai installation tag, which needs to be install above the closing &lt;/body&gt; tag on all pages
          of my website, is:<br>
          {{ parseHtml(scriptEmbed) }}<br>
          <br>
          As well, please consider this a request to have {{ userData.email }} added as an admin to our advertising
          accounts (Google Ads, Facebook Ads, etc.), which we may need to finalize portions of our organization’s
          integration with Converifai.<br>
          <br>
          Thank you,
        </p>
        <b-button
          class="mb-2"
          variant="primary"
          @click="copyEmailTemplate"
        >
          Click to Copy
        </b-button>
      </div>
    </b-modal>

    <!-- modal ticket create -->
    <b-modal
      id="modal-ticket"
      centered
      title="Open Ticket"
      size="lg"
      ok-title="Close"
      @ok="$bvModal.hide('modal-ticket')"
    >
      <p>
        Tell us about the situation and we'll help or connect you with someone who can!
      </p>
      <b-button
        variant="primary"
        class="mb-2"
        @click="openSupportDesk"
      >
        Create Ticket
      </b-button>
    </b-modal>

  </b-container>
</template>

<script>
import {
  BButton,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  // BFormSelect,
  // BFormTextarea,
  BModal,
  BRow,
  BFormCheckbox,
  BCard,
  BCardBody,
  BOverlay,
} from 'bootstrap-vue'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required, integer } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LinkGoogleAccount from '@/views/components/link-google-account/LinkGoogleAccount.vue'
import SelectGoogleCustomer from '@/views/components/select-google-customer/SelectGoogleCustomer.vue'
import ManageSiteDomains from '@/views/components/manage-site-domains/ManageSiteDomains.vue'
import SetConversionValue from '@/views/components/set-conversion-value/SetConversionValue.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    // BFormTextarea,
    // BFormInvalidFeedback,
    BModal,
    BFormCheckbox,
    BCard,
    BCardBody,
    BOverlay,

    // form wizard
    FormWizard,
    TabContent,

    // google customer settings
    LinkGoogleAccount,
    SelectGoogleCustomer,
    ManageSiteDomains,
    SetConversionValue,
  },
  data() {
    return {
      onboardingInitiated: false,
      copyButtonClicked: false,
      newAgencyForm: {
        name: null,
        google_customer_id: null,
      },
      agencies: [],
      domains: [],
      currentAgency: {},
      currentCustomer: null,

      // google auth
      googleAuthToken: null,
      googleComponentKey: 0, // key to force a refresh of google component
      showLinkOverlay: false,

      // form wizard
      wizardErrorStepLinkAccount: null,
      wizardErrorStepAgencyClients: null,
      wizardErrorStepDomains: null,
      wizardErrorStepConversion: null,
      scriptEmbed: '', // &lt;script src="https://source.converifai.com/converifai.js">&lt;/script>
      submitFormItems: [],
      showEmailTemplate: false,

      // form validation
      required,
      integer,
    }
  },
  computed: {
    googleAccess() {
      let googleAccess = null
      if (this.userData && this.userData.google_access && this.userData.google_accounts.length > 0) {
        googleAccess = this.userData.google_accounts
      }
      return googleAccess
    },
    visibleConversions() {
      const digestData = this.$store.getters['conversions/getAllConversions']
      return digestData ? digestData.data : []
    },
    userData() {
      const userData = this.$store.getters['users/getCurrentUser']
      return userData
    },
  },
  watch: {
    // start onboarding the first time agency is set/loaded
    currentAgency() {
      if (!this.onboardingInitiated) {
        this.loadOnboarding()
        this.onboardingInitiated = true
      }
      this.scriptEmbed = `&lt;script src="https://api.converifai.com/script/${this.currentAgency.uuid}/converifai.js">&lt;/script>`
    },
  },
  created() {
    // finalize google oauth if auth token exists
    if (this.$route.query.code) {
      this.googleAuthToken = this.$route.query.code
      this.finalizeGoogleAuth()
    }

    // get current user data or google customers
    if (!this.userData) {
      this.refreshUserData()
    } else if (this.userData.google_access) {
      this.getGoogleAdsCustomers()
    }

    // get conversions digest if domain is set
    if (this.userData && this.userData.domains && this.userData.domains.length > 0) {
      this.$store.dispatch('conversions/fetchAllConversions', { size: 1 })
        .then(() => {
          const visibleConversion = this.$store.getters['conversions/getAllConversions']
          if (visibleConversion.data.length > 0 && this.$route.name === 'landing' && !this.$route.query.code) {
            // if user hits '/' route and conversions exist, go to dashboard
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch(error => {
          console.log(error.response.data)
        })
    }
    // check for agency clients
    /* this.getAgencyClients() */
  },
  methods: {
    /**
     * refreshes userdata when userdata is changed
     */
    refreshUserData() {
      // refresh userdata
      this.$store.dispatch('users/fetchCurrentUser')
        .then(response => {
          console.log('refreshUserData', response)
          if (this.userData && this.userData.google_access) {
            this.getGoogleAdsCustomers()
          }
        })
    },
    /**
     * this method is called to initiate the onboarding wizard
     * it fills form data, decides the current step, gets data for the wizard
     */
    loadOnboarding() {
      // pre-fill form data and move to current step in onboarding wizard
      const onboardingSteps = {
        link_account: 0,
        enter_domain: 1,
        conversion_values: 2,
        add_script: 3,
        submit_form: 4,
      }
      console.log('loadOnboarding', this.userData, this.userData.google_access, this.currentCustomer)
      const onboardingRequirements = {
        link_account: this.userData && this.userData.google_access && this.currentCustomer,
        enter_domain: this.userData && this.userData.domains.length > 0,
        conversion_values: this.userData && this.userData.domains.length > 0 && this.userData.domains[0].conversion_values,
      }

      if (!onboardingRequirements.link_account) {
        // stay on first step if google account is not linked or customer is not set
        // this.$refs.onboardingWizard.changeTab(0, onboardingSteps.link_account)
      } else if (!onboardingRequirements.enter_domain) {
        // move to second step if domains do not exist
        this.$refs.onboardingWizard.changeTab(0, onboardingSteps.enter_domain)
      } else if (!onboardingRequirements.conversion_values) {
        // move to third step if conversion values do not exist
        this.$refs.onboardingWizard.changeTab(0, onboardingSteps.conversion_values)
      } else {
        // move to fourth step
        this.$refs.onboardingWizard.changeTab(0, onboardingSteps.add_script)
      }

      if (this.userData && this.userData.domains.length > 0) {
        // set domains
        this.domains = this.userData.domains
      }
    },
    /**
     * posts the auth token to complete hookup to google ads account
     */
    finalizeGoogleAuth() {
      this.$bvModal.show('modal-verifying') // show loading modal
      // this.showLinkOverlay = true
      useJwt.hookupGoogleAuth({
        auth_code: this.googleAuthToken,
      })
        .then(response => {
          console.log('finalizeGoogleAuth', response.data.data)

          this.refreshUserData()

          this.$nextTick(() => {
            this.googleComponentKey += 1 // force refresh of google component
            this.$bvModal.hide('modal-verifying') // hide loading modal
            this.showLinkOverlay = false
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Google Account Linked',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'You have successfully linked your Google Ads account.',
            },
          })
        })
        .catch(error => {
          console.log('finalizeGoogleAuth ERROR', error.response)
          this.$nextTick(() => {
            this.showLinkOverlay = false
            this.$bvModal.hide('modal-verifying') // hide loading modal
            this.displayErrorMessage(error.response.data.error || error.response.data)
          })
        })
    },

    /**
     * get customers from linked google ads account
     */
    getGoogleAdsCustomers() {
      useJwt.getAdCustomers()
        .then(response => {
          const { data } = response.data
          console.log('getAdCustomers', data)
          // TODO: find more efficient way to fill these fields
          const [customerData] = data
          this.submitFormItems = [
            { name: 'Account Name', value: this.userData.name },
            { name: 'Account Domain(s)', value: this.userData.domains.map(domain => domain.domain).join(', ') },
            { name: 'Google Ads Account Name', value: customerData.customer_descriptive_name },
            { name: 'Google Ads Account ID', value: customerData.customer_id },
          ]
        })
        .catch(error => {
          console.log('getGoogleAdsCustomers ERROR', error.response)
        })
    },

    /**
     * update domains when form changed
     */
    updateDomains(newDomainData) {
      this.domains = newDomainData
      this.submitFormItems[1] = { name: 'Account Domain(s)', value: this.domains.map(domain => domain.domain).join(', ') }
    },

    /**
     * update conversion values when form changed
     */
    updateConversionValue() {
      this.refreshUserData()
    },

    // wizard
    parseHtml(string) {
      const parsedString = string.replace(/&lt;/g, '<')
      return parsedString
    },
    copyText(text) {
      navigator.clipboard.writeText(text)
      this.copyButtonClicked = true
      setTimeout(() => {
        this.copyButtonClicked = false
      }, 1000)
    },
    finalizeWizard() {
      /* this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Check out your ad conversions here.',
          icon: 'EditIcon',
          variant: 'success',
        },
      }) */
      this.$router.push({ name: 'browse-conversions-new' })
    },
    checkStepLinkAccount() {
      if (this.userData && this.userData.google_access && this.currentCustomer) {
        return true
      }
      if (this.userData && this.userData.google_access && !this.currentCustomer) {
        this.wizardErrorStepLinkAccount = 'You must select a Google customer before you can continue.'
      } else {
        this.wizardErrorStepLinkAccount = 'You must link your Google Account before you can continue.'
      }
      return false
    },
    checkStepAgencyClients() {
      if (this.agencies && this.agencies.length > 0) {
        return true
      }
      this.wizardErrorStepAgencyClients = 'You must add an agency client before you can continue.'
      return false
    },
    checkStepDomains() {
      if (this.userData && this.userData.domains.length > 0) {
        return true
      }
      this.wizardErrorStepDomains = 'You must add a site URL to track before you can continue.'
      return false
    },
    /**
     * this method is called when the user clicks the "Next" button on the conversion values step
     * it checks to make sure the user has entered and saved conversion values before allowing them to continue
     * @returns {boolean}
     */
    checkStepConversion() {
      if (this.userData && this.userData.domains.length > 0 && this.userData.domains[0].conversion_values != null) {
        return true
      }
      this.wizardErrorStepConversion = 'You must save a conversion value before you can continue.'
      return false
    },

    /**
     * open the freshworks support desk to create a ticket
     */
    openSupportDesk() {
      console.log('openSupportDesk')
      this.$window.FreshworksWidget('open')
      this.$bvModal.hide('modal-ticket')
    },

    displayErrorMessage(errorMessage) {
      const formattedMessage = typeof errorMessage === 'object' && errorMessage !== null ? JSON.stringify(errorMessage) : errorMessage
      // display error message
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: formattedMessage,
        },
      })
    },
    openModal(modalId) {
      this.$bvModal.show(modalId)
    },
    copyEmailTemplate() {
      const emailTemplate = document.getElementById('emailTemplate')
      navigator.clipboard.writeText(emailTemplate.innerText)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.wizard-tab-container .form-group {
  max-width: 500px;
}

.bg-dark-transparency {
  background-color: rgba(134, 146, 208, 0.08);
}
</style>
